@import '../../../colors.scss';

.info-container {
    background-image: linear-gradient(#263a44, $Firefly);
    border-color: #29444f;
    border-radius: 7px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
}

.info-container-green {
    @extend .info-container;
    background-image: linear-gradient(#165A14, #4C8A3C);
    border-color: #48C62D;
}

.info-container-red {
    @extend .info-container;
    background-image: linear-gradient($Heath, $Pomegranate);
    border-color: $Pomegranate;
}


.audio-container{
    @extend .info-container;
    flex-direction: row;
    position: relative;

    input[type=range] {
        border-radius: 6px;
        -webkit-appearance: none;
        outline: none;
        background-color: #F79796;
        height:6px;
    }
      
    input[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none;
        background-color: red;
        width: 16px;
        height: 16px;
        border-radius:16px;
    }
}
.info-title {
    font-size: 1.6em;
    font-weight: 500;
}

.info-message {
    font-size: 0.6em;
    font-weight: 400;
    margin-top: -5px;
}

@media screen and (max-width: 500px) {
    .audio-container{
        input[type=range] {
            width: 68px !important;
            margin: 0px !important;
        }
    }
}
@import '../../../colors.scss';
.menu-header {
    background-image: url("../../../assets/BG/MainMenu.jpg");
    /* Full height */
    height: 100%;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
    font-family: "Montserrat";
}

//
.menu {
    background-color: rgba($color: $Elephant, $alpha: 0.9);
    border-style: solid;
    border-color: rgba($color: $Pickled-Bluewood, $alpha: 1);
    border-width: 1px;
    border-radius: 10px;
    margin-top: 110px;
    text-align: center;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 10px;
    padding : 25px;
    width: 1170px;
    height: auto;
}

@media screen and (max-width: 1200px) {
    .menu {
        width: 96%;
    }
  }
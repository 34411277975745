.drop-down {
    background-color: rgba($color: #3a4e5a, $alpha: 1);
    background-image: url("../../assets/TTGame/dropdown.svg");
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 50%;
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
    border-radius: 29px;
    border-color: white;
    border-width: 1px;
    font-size: 0.9em;
    width: 60%;
    min-height: 50px;
    padding-left: 20px;
    margin-top: 10px;
    color: white;
}

.drop-down:focus {
    outline: none;
    box-shadow: 0 0 3pt 2pt #3a4e5a;
}

  @media screen and (max-width: 500px) {
    .drop-down {
        font-size: 0.7em;
    }
  }
.content-container{
    background-color: #0E2634;
    border-color: #859CA9;
    border-radius: 7px;
    border-style: solid;
    border-width: 1px;
    height: auto;
    max-width: 70%;
    min-width: 58%;
    padding:10px;
    align-self: center;
    text-align: center;
    margin-top: 20px;
    position: relative;
}

.zoom-content-container{
    background-color: #0E2634;
    border-color: #859CA9;
    border-radius: 7px;
    border-style: solid;
    border-width: 1px;
    height: 95vh;
    max-height: auto;
    max-width: 80%;
    min-width: 75%;
    padding:10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    margin-top: 20px;
}

.content-container-text{
    display: flex;
    justify-content: center;
    color:white;
    margin-top: 5px;
    font-size: 0.8em;
}

.audio-bar{
    background-color: #F53B39;
    height: 6px;
    width: 100%;
    align-self: center;
    margin-top: 30px;
    position: relative;
    color:#F53B39;
    display: flex;
    align-items: center;
    justify-content: center;
}
.audio-timer{
    text-align: center;
    width: 100%;
    display:flex;
    justify-content: space-evenly;
    font-size: 0.8em;
    color:white;
    margin-top: 5px;
    margin-bottom: 30px;
}

@media screen and (max-width: 800px) {
    .content-container{
        max-width: 80% !important;
    }
    .zoom-content-container{
        max-width: 80% !important;
    }
  }
@media screen and (max-width: 500px) {
    .content-container{
        max-width: 100% !important;
    }
    .zoom.content-container{
        max-width: 100% !important;
    }
    .audio-timer{
        font-size: 0.7em;
    }
  }

.content-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    min-width: 80%;
    justify-content: space-around;
    align-items: baseline;
    align-self: center;

     @media 
     (max-width: 991px) {
        min-width: 90%;
    }
    @media 
     (max-width: 768px) {
        min-width: 100%;
    }
}

.picture-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    @media 
     (max-width: 768px) {
        flex: 0 1 50%;
    }

    @media 
     (max-width: 400px) {
        flex: 0 1 55%;
    }
}

.serve-button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-top: 25px;
}
.menu-header {
  /* Full height */
  height: 100%;
  /* Center and scale the image nicely */
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #09161e;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: white;
  font-family: "Montserrat";
  font-size: 1.1em;
  font-weight: 400;
}

.slot-container {
  display: flex;
  flex-direction: column;
  align-items: start;

  width: 100%;
  margin-top: 10px;
}
.button-container {
  width: 90%;

  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-self: center;
  align-items: center;
}

.game-text {
  font-size: 1.3em;
  font-weight: 400;
  align-self: flex-start;
  margin-left: 50px;
  margin-bottom: 20px;
}

.code-result-text {
  text-align: start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media screen and (max-width: 1200px) {
  .game-text {
    margin-left: 20px;
  }
  .code-result-text {
    margin-left: 0px;
  }
}
@media screen and (max-width: 800px) {
  .slot-container > button {
    width: 320px !important;
    font-size: 0.9em !important;
  }
  .button-container {
    flex-direction: column;
  }
}
@media screen and (max-width: 500px) {
  .game-text {
    font-size: 1.1em;
  }
  .slot-container > button {
    width: 280px !important;
    height: 55px !important;
    font-size: 0.7em !important;
  }
}

@media screen and (max-width: 300px) {
  .game-text {
    font-size: 1em;
  }
  .slot-container > button {
    width: 220px !important;
    height: 50px !important;
    font-size: 0.6em !important;
  }
}

.content-container {
  background-color: #0E2634;
  border-color: #859CA9;
  border-radius: 7px;
  border-style: solid;
  border-width: 1px;
  height: auto;
  padding: 10px;
  align-self: center;
  text-align: center;
  margin-top: 20px;
  position: relative;


  .images-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    cursor: url('../../../assets/BlackeyeTreasure/shovel.svg') 2 53, auto;

    .image-container {
      width: calc(12.5%);
      height: 95px;
      position: relative;

      .layer1-image {
        position: relative;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        z-index: 1;
      }

      .layer2-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
      }
    }
  }

  .content-container-text {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    color: white;
    margin-top: 20px;
    font-size: 0.8em;
    margin-bottom: 10px;
  }
}
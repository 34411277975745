.hint {
    background-color: rgba($color: #0E2634, $alpha: 1.0);
    border-style: solid;
    border-color: #859CA9;
    border-width: 1px;
    border-radius: 7px;
    width: 95%;
    height: auto;
    display: flex;
    padding-left: 20px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 0.9em;
}

@media screen and (max-width: 500px) {
    .hint {
        flex-direction: column;
        align-items: center;
    }
    .hint > span{
        margin-left: 0 !important;
        margin-right: 1px !important;
    }
  }
.footer-images{
    background-color: #0E2634;
    border-style: solid;
    border-color:#859CA9;
    border-width: 1px;
    border-radius: 7px;

    align-self: center;

    display: flex;
    justify-content: space-around;
    flex-direction: column;

    width:320px;
    height: auto;
    margin-top: 20px;
    padding: 0 10px 5px;
    font-size: 0.8em;
}
.item-container{
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.image-container{
    background-color: #09161E;
    display: flex;
    flex-direction: column;
    align-items: center;
    // width: 48%;
    width: 130px;
    height: 180px;
    margin: 10px 0;
    padding:5px 0 5px 0;
}

  @media screen and (max-width: 500px) {

    .footer-images{
        font-size: 0.7em;
        width: 275px;
    }

    .image-container{
        width: 48%;
    }
  }
  @media screen and (max-width: 300px) {

    .footer-images{
        width: 160px;
    }
    .item-container{
        flex-direction: column;
    }
    .image-container{
        width: 90%;
    }
  }

.audio-container{

    background-color: #0E2634;
    border-color: #859CA9;
    border-radius: 7px;
    border-style: solid;
    border-width: 1px;

    height: 160px;
    width: 350px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    text-align: center;
    padding:10px 0 0 10px;
    color: #707070;

    input[type=range] {
      margin-left: -10px;
      margin-top: 5px;
      border-radius: 6px;

      -webkit-appearance: none;
      outline: none;
      background-color: #F79796;
      width: 90%;
      height:6px;
    }
    
    input[type=range]::-webkit-slider-thumb {
      -webkit-appearance: none;
      background-color: red;
      width: 20px;
      height: 20px;
      border-radius:20px;
    }
}

.audio-bar{
    height: 6px;
    width: 90%;
    align-self: center;
    margin-left: -10px;
    margin-top: 55px;
    position: relative;
    color:#F53B39;
    display: flex;
    align-items: center;
    justify-content: center;
}


.slide-bar{
  // background: linear-gradient(to right, #82CFD0 0%, #82CFD0 50%, #F79796 50%,#F79796 100%);
  // border: solid 1px #F79796;
  // border-radius: 8px;
  // height: 6px;
  // width: 90%;
  // align-self: center;
  // margin-left: -10px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // outline: none;
  // -webkit-appearance: none;
}

  @media screen and (max-width: 400px) {
    .audio-container{
        width: 100%;
    }
  }
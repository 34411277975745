@import '../../colors.scss';

.navbar.custom {
  position: absolute;
  background-color: #ffffff00 !important;
  width: 100%;
}

.navbar.custom .custom-margin {
  margin-left: 18%;
}

.navbar.custom #navbarCollapse {
  margin-right: 18%;
}

.navbar.custom button.btn.btn-light.nav-item-custom-btn {
  background-color: $Elephant;
  border-style: solid;
  border-color: $Pickled-Bluewood;
  border-width: 1px;
  border-radius: 29px;
  color: white;
  padding: 5px 20px;
  text-align: center;
  margin: 4px 2px;
  cursor: pointer;
  font-size: small;
  font-weight: 500;
}

button.btn.btn-light.nav-item-custom-btn:focus{
  outline: none;
  box-shadow: none;
}

.connection-button{
  background-color: $Elephant;
  border-style: solid;
  border-color: $Pickled-Bluewood;
  border-width: 1px;
  border-radius: 29px;
  color: white;
  padding: 5px 20px;
  text-align: center;
  margin: 4px 2px;
  font-size: small;
  font-weight: 500;
  cursor: default !important;
}

.connection-button:focus{
  outline: none;
  box-shadow: none;
}


.nav-item-custom-btn-img {
  height: 15px;
  margin: 2px 0px;
}

@media screen and (max-width: 400px) {
  .navbar.custom .custom-margin {
    margin-left: 5px;
  }
}

@media screen and (max-width: 1500px) {
  .navbar.custom .custom-margin {
    margin-left: 10%;
  }
  
  .navbar.custom #navbarCollapse {
    margin-right: 10%;
  }
}

@media screen and (max-width: 1200px) {
  .navbar.custom button.btn.btn-light.nav-item-custom-btn{
    font-size: 0.75em;
  }
  .navbar.custom .custom-margin {
    margin-left: 5px;
  }
  
  .navbar.custom #navbarCollapse {
    margin-right: 0px;
  }
}

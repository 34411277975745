.content-container {
  background-color: #0E2634;
  border-color: #859CA9;
  border-radius: 7px;
  border-style: solid;
  border-width: 1px;
  height: auto;
  width: 100%;
   padding: 10px;
  align-self: center;
  text-align: center;
  margin-top: 20px;
  position: relative;
}

.content-container-text {
  display: flex;
  justify-content: center;
  color: white;
  margin-top: 5px;
  font-size: 0.8em;
}

.input-field {
    background-color: rgba($color: #3a4e5a, $alpha: 1);
    border-radius: 29px;
  
    border-color: white;
    font-size: 1em;
    font-weight: 400;
    width: 70%;
    min-height: 50px;
    padding-left: 20px;
    color: white;
    margin-top: 10px;
  }
  .input-field:focus {
    outline: none;
    box-shadow: 0 0 3pt 2pt #3a4e5a;
  }
  .input-field::placeholder{
    color:white;
  }
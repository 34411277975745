

.menu-card {
  position: relative;
}

.menu-card .menu-card-background {
  width: 200px;
  height: auto;
}

.menu-card .menu-card-logo {
  position: absolute;
  left: 50px;
  bottom:0px;
  width: 100px;
  opacity: 0.5;
}

.menu-card .menu-card-label {
  position: absolute;
  left: 0;
  top: 80px;
  width: 200px;
  font-size: 22px;
  font-weight: 500;
  color: white;
  opacity: 0.5;
}

.menu-card.active .menu-card-label,
.menu-card.active .menu-card-logo {
  opacity: 1;
}
.menu-card-opacity {
  opacity: 1;
}

@media screen and (max-width: 991px) {
  .menu-card-container {
    max-width: 30rem;
  }
}

.menu-link {
  color: #61dafb;
}

.header-slot {
  background-color: #0a1821;
  border-color: white;
  border-width: 1px;
  border-style: solid;

  width: 955px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;
  font-size: 1em;
}

.item-slot {
  @extend .header-slot;
  background-color: #2d4955;
  border-top: none;
  border-bottom-width: 0;
  height: 31px;
}

.rank-slot {
  flex: 2;
}

.team-name-slot {
  flex: 6;
}
.result-team-name-slot {
  flex: 2;
}
.hint-slot {
  flex: 3;
}

@media screen and (max-width: 1200px) {
  .header-slot {
    width: 95%;
    font-size: 0.9em;
  }
  .item-slot {
    width: 95%;
  }
}

@media screen and (max-width: 800px) {
  .header-slot {
    font-size: 0.7em;
  }
  .team-name-slot {
    // margin-right:20px;
  }
}

@media screen and (max-width: 500px) {
  .header-slot {
    font-size: 0.6em;
  }
  .team-name-slot {
    margin-right: 0px;
  }
}

@media screen and (max-width: 400px) {
  .header-slot {
    font-size: 0.55em;
  }
  .team-name-slot {
    margin-right: 0px;
  }
}

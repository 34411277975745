.question-panel {
    border-style: solid;
    background-color: rgba($color: #113446, $alpha: 0.95);
    border-color: rgba($color: #35515D, $alpha: 1);
    border-width: 1px;
    border-radius: 10px;

    width: 71%;
    // height: 400px;
    display: flex;
    flex-direction: column;
    text-align: start;
    padding: 20px 0 40px 20px;
    overflow: auto;

}

.skip-challenge-box-container {
    border: none !important;
    max-width: 737px !important;
    min-height: 332px !important;
    padding-left: 0px !important;
    padding-bottom: 35px !important;
}

.skip-challenge-box-title {
    padding-left: 50px !important;
    font-size: 1.75em !important;
}

.skip-challenge-box-message-container {
    padding-left: 50px !important;
    margin-left: 0 !important;
    margin-top: 10px !important;
}

.skip-challenge-box-button-container {
    width: 100% !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    justify-content: space-evenly !important;
}

.question-panel::-webkit-scrollbar {
    width: 8px;
}

.input-button-container {
    display: flex;
    justify-content: space-between;
    width: 260px;
    height: auto;
}

.button-container {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.BBH-button-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


.BT-step9>input {
    width: 97% !important;
}

.BT-step9 .button-container {
    @extend .button-container;
    margin-top: 20px;
    margin-bottom: 20px;
}


@media screen and (max-width: 800px) {

    .question-panel {
        width: 100%;
    }

    .question-panel>input {
        width: 440px !important;
    }

    .question-panel>select {
        width: 440px !important;
    }

    .button-container {
        width: 100%;
    }

    .BBH-button-container>button[style] {
        font-size: 0.7em !important;
        width: 210px !important;
        height: 56px !important;
    }

    .button-container>button[style] {
        font-size: 0.7em !important;
        width: 210px !important;
        height: 56px !important;
    }
}

@media screen and (max-width: 500px) {

    .BBH-button-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .question-panel>input {
        width: 90% !important;
    }

    .question-panel>select {
        width: 90% !important;
    }

    .button-container {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }

    .BBH-button-container>button[style],
    .BBH-button-container>input[style] {
        width: 90% !important;
        margin: 10px 0px 0px 0px !important;
    }

    .button-container>button[style] {
        width: 90% !important;
        margin: 0 0 8px 0 !important;
    }

    .input-button-container {
        margin-bottom: 10px;
    }

    .input-button-container>button {
        width: 51px !important;
        height: 55px !important;
    }
}
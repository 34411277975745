.model-viewer-wrapper {
  background-color: #0E2634;
  border-color: #859CA9;
  border-radius: 7px;
  border-style: solid;
  border-width: 1px;
  height: 662px;
  max-width: 75%;
  min-width: 68%;
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .model-viewer {
    height: 90%;
    width: 95%;
    align-self: center;
    --progress-bar-color: rgba(0, 0, 0, 0);
    --poster-color: transparent;

    :focus-visible {
      outline: none;
      outline-style: none;
    }
  }

  .footer {
    margin-bottom: 10px;
    color: #fff;
    font-size: 14px;
    text-align: center;
  }
}

@media screen and (max-width: 800px) {
  .model-viewer-wrapper {
    width: 100%;
  }
}


@media screen and (max-width: 700px) {
  .model-viewer-wrapper {
    height: 450px;
  }
}

@media screen and (max-width: 500px) {
  .model-viewer-wrapper {
    height: 300px;
  }
}
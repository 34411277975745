@import '../../../colors.scss';

.hud-panel {
    background-color: rgba($color: $Elephant, $alpha: 0.95);
    border-style: solid;
    border-color: rgba($color: $Pickled-Bluewood, $alpha: 1);
    border-width: 1px;
    border-radius: 10px;
    width: 28%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
    padding: 20px 0 20px 0;
}


.game-hud {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
}

.slot-container {
    width: 660px;
    display: flex;
    justify-content: flex-end;
}

.result-hud-slot {
    width: auto;
    display: flex;
    justify-content: center;
    flex-direction: row;
}

@media screen and (max-width: 1100px) {

    .hud-panel>div[style] {
        width: 180px !important;
    }

    .game-hud {
        justify-content: flex-start;
        flex-direction: column;
    }

    .slot-container {
        margin: 0;
        margin-top: 10px;
        width: 660px;
        justify-content: center;
    }
}

@media screen and (max-width: 800px) {

    .slot-container {
        flex-direction: column;
        align-items: center;
        width: 250px;
    }

    .slot-container>div[style] {
        width: 200px !important;
        margin-top: 5px !important;
        margin-right: 0px !important;
    }

    .result-hud-slot {
        flex-direction: column-reverse;
        align-items: center;
    }

    .result-hud-slot>div[style] {
        width: 190px !important;
        margin-top: 5px !important;
        margin-right: 0px !important;
        margin-left: 0px !important;
    }

    .hud-panel {
        height: 350px;
        width: 100%;
        margin-top: 10px;
    }

    .hud-panel>div[style] {
        width: 250px !important;
    }
}
.how_to_play_tt{
    background-image: url('./assets/BG/IntroBG.jpg');
}

.question_tt{
    background-image: url('./assets/BG/GameSteps.jpg');
    background-position: right;
}

.result_tt_success{
    background-image: url('./assets/BG/Result.jpg');
}

.result_tt_failure{
    background-image: url('./assets/BG/Result.jpg');
}

.main_menu_tt{
    background-image: url('./assets/BG/MainMenu.jpg');
}

.leaderboard_tt{
    background-image: url('./assets/BG/Leaderboard.jpg');
}

.result-viewer_tt{
    background-image: url('./assets/BG/result-viewer.jpg');
}



.how_to_play_ttjr{
    background-image: url('./assets/BG/IntroBG.jpg');
}

.question_ttjr{
    background-image: url('./assets/BG/GameSteps.jpg');
    background-position: right;
}

.result_ttjr_success{
    background-image: url('./assets/BG/Result.jpg');
}

.result_ttjr_failure{
    background-image: url('./assets/BG/Result.jpg');
}

.main_menu_ttjr{
    background-image: url('./assets/TTJrGame/BG/bombttjr.jpg');
}

.leaderboard_ttjr{
    background-image: url('./assets/BG/Leaderboard.jpg');
}

.result-viewer_ttjr{
    background-image: url('./assets/BG/result-viewer.jpg');
}



.how_to_play_bbh{
    background-image: url('./assets/BBHGame/BG/GameIntro.jpg');
}

.question_bbh{
    background-image: url('./assets/BBHGame/BG/Question.jpg');
    background-position: bottom;
}

.result_bbh_failure{
    background-image: url('./assets/BBHGame/BG/ResultFail.jpg');
}

.result_bbh_success{
    background-image: url('./assets/BBHGame/BG/ResultPass.jpg');
}

.main_menu_bbh{
    background-image: url('./assets/BBHGame/BG/MainMenu.jpg');
}

.leaderboard_bbh{
    background-image: url('./assets/BBHGame/BG/Leaderboard.jpg');
}


.how_to_play_jh{
    background-image: url('./assets/HangoverGame/BG/rules.jpg');
}

.question_jh{
    background-image: url('./assets/HangoverGame/BG/step.jpg');
    background-position: right;
}

.result_jh_success{
    background-image: url('./assets/HangoverGame/BG/success.jpg');
}

.result_jh_failure{
    background-image: url('./assets/HangoverGame/BG/fail.jpg');
}

.main_menu_jh{
    background-image: url('./assets/HangoverGame/BG/menu.jpg');
}

.leaderboard_jh{
    background-image: url('./assets/HangoverGame/BG/leaderboard.jpg');
}



.how_to_play_mayday{
    background-image: url('./assets/MayDay/BG/IntroBG.jpg');
}

.question_mayday{
    background-image: url('./assets/MayDay/BG/GameSteps.jpg');
    background-position: right;
}

.result_mayday_success{
    background-image: url('./assets/MayDay/BG/Result.jpg');
}

.result_mayday_failure{
    background-image: url('./assets/MayDay/BG/Result.jpg');
}

.main_menu_mayday{
    background-image: url('./assets/MayDay/BG/MainMenu.jpg');
}

.leaderboard_mayday{
    background-image: url('./assets/MayDay/BG/Leaderboard.jpg');
}


.how_to_play_bt{
    background-image: url('https://storage.googleapis.com/virtual-escape-assets/Backgrounds/BT/GameIntro.jpg');
}

.question_bt{
    background-image: url('https://storage.googleapis.com/virtual-escape-assets/Backgrounds/BT/GameSteps.jpg');
    background-position: right;
}

.result_bt_success{
    background-image: url('https://storage.googleapis.com/virtual-escape-assets/Backgrounds/BT/Success.jpg');
}

.result_bt_failure{
    background-image: url('https://storage.googleapis.com/virtual-escape-assets/Backgrounds/BT/Success.jpg');
}

.main_menu_bt{
    background-image: url('https://storage.googleapis.com/virtual-escape-assets/Backgrounds/BT/MainMenu.jpg');
}

.leaderboard_bt{
    background-image: url('https://storage.googleapis.com/virtual-escape-assets/Backgrounds/BT/Leaderboard.jpg');
}
.container{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

@media screen and (max-width: 500px) {
    .container{
        flex-direction: column;
    }
}
@import '../../colors.scss';

.login-button-green {
    background-image: linear-gradient($Conifer, $Bilbao);
    background-blend-mode: normal;
}

.login-button-red {
    background-image: linear-gradient($Red, $Crown-of-Thorns);
    background-blend-mode: normal;
}

.login-button-grey {
    background-image: linear-gradient($Rolling-Stone, $Cape-Cod); 
    background-blend-mode: normal;
}

.login-button {
    margin-top: 10px;
    border-style: none;
    font-size: 0.8em;
    color: white;
    font-weight: 500 !important;
}

// .login-button:hover {
//     box-shadow: 0 0 3pt 2pt #3a4e5a;
// }
.login-button:focus {
    outline: none;
}

.login-button:disabled{
    background-image: linear-gradient($San-Juan, $San-Juan);
    color: #788B96;
}

.login-button:active {
    transform: scale(0.98); 
} 


@media screen and (max-width: 500px) {
    .login-button{
        width: 280px !important;
        height: 55px !important;
        font-size: 0.7em !important;
    }
}

@media screen and (max-width: 300px) {
    .login-button{
        width: 220px !important;
        height: 50px !important;
        font-size: 0.6em !important;
    }
}
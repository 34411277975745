.container {
  background-color: rgba($color: #010507, $alpha: 0.95);
  border-color: rgba($color: #35515d, $alpha: 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 20px;
  padding-left: 20px;
  min-height: 360px;
  height: auto;
  width: 800px;
  color: white;
  font-size: 16px;
  z-index: 500;
}

.button-container {
  align-self: center;
  align-items: center;
  display: flex;
  justify-content: space-around;
  width: 75%;
  height: 35%;
}

@media screen and (max-width: 1200px) {
  .container {
    width: 780px;
    font-size: 0.9em;
  }

  .button-container {
    width: 80%;
  }
}

@media screen and (max-width: 800px) {
  .container {
    width: 480px;
    font-size: 0.8em;
  }

  .button-container {
    flex-direction: column;
    width: auto;
  }

  .button-container>button {
    width: 220px !important;
    height: 46px !important;
    font-size: 1.2em !important;
  }
}

@media screen and (max-width: 500px) {
  .container {
    width: 300px;
    font-size: 0.65em;
  }

  .button-container>button {
    font-size: 1.2em !important;
  }
}
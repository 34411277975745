.menu {
  text-align: center;
  // font-family: 'SkyFont';
  font-family: "Montserrat";
}

//
.menu-logo {
  height: 40vmin;
  pointer-events: none;
}


.menu-header {
  /* Full height */
  height: 100%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #09161E;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.menu-card-container {
  max-width: 55rem;
  margin: auto;

}
.bottom-logo-container{
  font-size: 12px;
  text-align: left;
  position: absolute;
  bottom: 20px;
}
.bottom-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 261px;
  height: 56px;
  background: linear-gradient(#113446aa,#113446aa);
  border-color: #35515D;
  border-style: solid;
  border-width: 1px;
}
.title-container{
  font-size: larger;
  font-weight: 400;
}
.time-ticking-title{
  font-weight: 500;
}

@media screen and (max-width: 991px) {
  .menu-card-container {
    max-width: 30rem;
  }
  .title-container{
    margin-top: 80px;
  }
  .bottom-logo-container {
    position: relative;
    margin: 40px 0px 0px 0px;
  }
}
@media screen and (max-width: 775px) {
  .title-container{
    margin-top: 80px;
  }
}
@media screen and (max-width: 580px) {
  .menu-card-container {
    margin-bottom: 60px;
  }
  .bottom-logo-container {
    position: relative;
    margin: 0px 0px 10px 0px;
  }
}
.menu-link {
  color: #61dafb;
}


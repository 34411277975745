body {
  margin: 0;
  font-family: "Montserrat","SkyFont", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Montserrat","SkyFont","Treasuremap",source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
    
}

@font-face {
  font-family: "SkyFont";
  src: local("SkyFont"),url('./fonts/Skyfont.otf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: "Treasuremap";
  src: local("Treasuremap"),url('./fonts/Treasuremap.ttf') format('truetype');
  font-weight: normal;
}

::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #0E2634; 
  border-radius: 29px;
  border-style: none;
  box-shadow: inset 0 0 5px #254A60; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #2C5167;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #254A60; 
}

::-webkit-scrollbar-corner{
  background: #0E2634;
}

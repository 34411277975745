.content-container{
    background-color: #0E2634;
    border-color: #859CA9;
    border-radius: 7px;
    border-style: solid;
    border-width: 1px;
    height: auto;
    max-width: 70%;
    min-width: 70%;
    padding:10px 20px 10px 20px;
    align-self: center;
    text-align: center;
    margin-top: 20px;
    position: relative;
}

@media screen and (max-width: 800px) {
    .content-container{
        max-width: 80% !important;
    }
  }

@media screen and (max-width: 500px) {
    .content-container{
        max-width: 100% !important;
    }
}
$Firefly: #0E2634;
$Elephant: #113446;
$Pickled-Bluewood:#35515D;
$Heath: #5A1414;
$Pomegranate: #F53B39;
$Red: #F80705;
$Crown-of-Thorns: #7B1E1D;
$Rolling-Stone: #75838B;
$Cape-Cod: #3B4246;
$Conifer: #B4EC51;
$Bilbao:#377B1B;
$San-Juan: #2C5167;

.dropdowns-container {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 680px;
    padding: 0 25px
}

.wrapper {
    display: flex;
    align-items: center;
}

.dropdowns-container>div {
    flex: 0 1 45%;
}


@media (max-width: 600px) {
    .dropdowns-container{
        flex-direction: column;
    }
    .dropdowns-container>div {
        flex: 0 1 100%;
    }
}

.backdrop{
    width: 100%;
    height: 100%;
    z-index: 100;
    position:fixed;
    left:0;
    top:0;
    display: flex;
    background-color: rgba($color: #000, $alpha: 0.5);
    justify-content: center;
    align-items: center;
}
.backdrop-black{
    background-color: rgba($color: #000, $alpha: 0.5);
}
.backdrop-black-noalpha{
    background-color: rgba($color: #000, $alpha: 1);
}
